<template>
  <b-overlay :show="loading">
    <div class="container">
      <div class="d-flex flex-column h-full w-100 h-50">
        <div style="width: 200px">
          <img class="mt-5" src="@/assets/mda_1628620680.png" style="width: 70px;"/>
          <h6 class="mt-2 text-align-left" style="font-weight: bold;">Ghana Tourism Authority</h6>
        </div>

        <div style="background-color: #f8f9fa; border-width: 1px; border-color: #6c757d" class="bg-gray w-100 h-50 pt-5" v-if="!loading && !transactionFailed && transactionReference">
          <p class="mt-4 text-center" style="color: green">Payment completed successful. You can now close this tab.</p>

        </div>

        <div style="background-color: #f8f9fa; border-width: 1px; border-color: #6c757d" class="bg-gray w-100 h-50 pt-5" v-if="!loading && transactionFailed">
          <p class="mt-4 text-center" style="color: tomato;">There was an issue with your payment. Please try again later. If issue persist contact support.</p>
        </div>

        <div style="background-color: #f8f9fa; border-width: 1px; border-color: #6c757d" class="bg-gray w-100 h-50 pt-5" v-if="!loading && !transactionFailed && !transactionReference">
          <p class="mt-4 text-center" style="color: tomato;">Invalid credentials provided.</p>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay } from "bootstrap-vue";

import axios from "../services/axios";

export default {
  components: {
    BOverlay
  },
  data(){
    return {
      loading: false,
      transactionFailed: false
    }
  },
  computed: {
    transactionReference() {
      const { 
        reference 
      } = this.$route.query;

      return reference
    }
  },
  created(){
    if ( this.transactionReference ){
      this.verifyTransaction()
    }
  },
  methods: {
    async verifyTransaction(){
      try {
        this.loading = true;

        const transaction_status = await axios.get(`/gta-single-window/module2/payments/${this.transactionReference}/verify`, {});
        const { success } = transaction_status.data.data
        console.log('transaction_status', success)

        if ( success ){
          this.$swal({
            title: "Payment successful",
            text: "Your registration payment has been completed successfully. You can now proceed to close this tab.",
            icon: "success"
          });
        } else {
          this.transactionFailed = true;
          this.$swal({
            title: "Payment failed.",
            text: "Your registration payment was not successful. Please contact support if you any difficulties..",
            icon: "danger"
          });
        }
      } catch(error){
        this.$swal({
          title: "An Error Occured!",
          text: error.message,
          icon: "danger"
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>